import axios, { type AxiosRequestConfig, type AxiosInstance } from 'axios';
type ConstructorProps = {
  baseUrl?: string;
};

/**
 * LogClient
 */
export class LogClient {
  private client: AxiosInstance;

  /**
   * コンストラクタ
   * @param props - プロパティ
   * @param props.baseUrl - ベースURL
   */
  constructor(props: ConstructorProps = {}) {
    this.client = axios.create({
      baseURL: props.baseUrl || undefined,
      withCredentials: true,
    });
  }

  /**
   * post処理を行い、レスポンスデータを返却する
   * @param path - url
   * @param requestBody - リクエストボディ
   * @param config - コンフィグ
   */
  async post<T>(path: string, requestBody: object, config?: AxiosRequestConfig): Promise<T | undefined> {
    const response = await this.client
      .post<T>(path, { ...requestBody, windowLocationHref: window && window.location.href }, config)
      .catch((err) => {});
    return response?.data;
  }
}
